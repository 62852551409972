.wrapper {
    thead .ant-table-cell {
        padding: 10px !important; }
    margin: 0px 30px 60px;
    font-family: Gilroy;
    .container {
        padding: 5px 20px;
        margin: 30px 0px;
        background: #fff;
        box-shadow: 0 1 8 0 rgba(0, 0, 0, 0.14); }
    .filters {
        padding: 16px;
        .filter-dropdowns {
            .ant-select, .ant-picker {
                margin-top: 5px;
                width: 100%; }
            .ant-select-selector, .ant-picker, .ant-select-selection-search-input {
                height: 50px !important;
                align-items: center; }
            display: grid;
            grid-template-columns: repeat(3, 1fr); /* Creates 3 equal-width columns */;
            color: rgba(76, 96, 114, 1);
            font-size: 14px;
            gap: 20px;
            align-items: end; }
        .download-csv {
            display: flex;
            justify-content: end;
            margin-top: 18px;
            button {
                height: 44px;
                padding: 0px 18px;
                border-radius: 0px;
                .anticon svg {
                    width: 12px;
                    color: #fff; } } } }
    .table-container {
        overflow-x: scroll; }

    ::-webkit-scrollbar {
        height: 8px; }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px; }
    ::-webkit-scrollbar-thumb {
        background: rgba(157, 167, 178, 1);
        border-radius: 10px; } }
.pt-18 {
    padding-top: 18px; }
