.pricing-table .ant-table {
    border-radius: 20px;
    overflow: hidden;
}

.pricing-table .ant-table-thead>tr>th,
.pricing-table .ant-table-tbody>tr>td {
    border: none;
}


.pricing-table .ant-table-tbody>tr>td {
    padding: 16px;
}

.column-head {
    text-align: left;
    padding-left: 10px;
    padding-top: 20px;
}

.column-service {
    text-align: center;
    font-size: 20px;
}

.pricing-table {
    margin: 40px 0px 20px 0px;
    border-radius: 16px;
    box-shadow: 4px 4px 20px 0px rgba(0, 0, 0, 0.05)
}

.insights-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #9FB8C7;
}

.bronze-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #B17A6A;
}

.silver-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #B6B6B9;
}

.gold-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #F9A72A;
}

.remote-text {
    font-family: Gilroy-Bold;
    font-size: 22px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #6598b9;
}

.membership-price-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
    color: #36383A;
}

.session-info{
    margin: 40px 0px 40px 0px;

}
.table-sub-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.366666704416275px;
    text-align: left;
}

.service-list-container{
    text-align: left;
    padding-right: 2rem;
}

/* .selected {
    background-color: #264382 !important;
    color: #ffff !important;
    border: 1px solid #264382 !important;
    letter-spacing: 2.8px;
    font-size: 14px;
    margin-top: 16px;
} */
.no-hover:hover {
    background-color: transparent !important;
}

.ant-table td {
    border: none !important;
}

.ant-table th {
    border: none !important;
    border-bottom: 1px solid #f0f0f0 !important;
}

td.highlighted-column {
    background-color: #E2F2FF !important;
    border-left: 2px solid #264382 !important;
    border-right: 2px solid #264382 !important;
    position: relative;
}

th.ant-table-cell.highlighted-column {
    border-top: 2px solid #264382 !important;
    border-left: 2px solid #264382 !important;
    border-right: 2px solid #264382 !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    background-color: #E2F2FF !important;
}

.ant-table-tbody>tr:last-child td.highlighted-column {
    border-bottom: 2px solid #264382 !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.selected-btn {
    background: #264382 !important;
    color: #ffffff;
}

.ant-table-thead>tr>th {
    padding-bottom: 40px !important;
}



.ant-table-container {
    border: none !important;
}
.tip-text {
    letter-spacing: 1px;
    font-weight: 400;
    margin-bottom: 30px;
}

.contact-email {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    font-size: 18px;
    font-weight: 400;
}

.contact-email-text {
    margin-right: 6px;
}

.contact-email a {
    text-decoration: underline;
}

.table-wrapper .ant-table {
    border-radius: 20px;
    overflow: hidden;
}

.table-wrapper .ant-table-thead>tr>th:first-child {
    border-top-left-radius: 20px;
}

.table-wrapper .ant-table-thead>tr>th:last-child {
    border-top-right-radius: 20px;
}

.table-wrapper .ant-table-tbody>tr:last-child>td:first-child {
    border-bottom-left-radius: 20px;
}

.table-wrapper .ant-table-tbody>tr:last-child>td:last-child {
    border-bottom-right-radius: 20px;
}

.ant-table-thead>tr>th {
    background-color: white !important;
    border-bottom: none;
}

.ant-table-tbody tr:last-child td:nth-child(2) {
    vertical-align: top !important;
}

.ant-table-tbody tr:last-child td:nth-child(3) {
    vertical-align: top !important;
}

.ant-table-thead th {
    padding: 44px 16px !important;
    text-align: center;
    vertical-align: middle;
}

.sub-text-price {
    letter-spacing: 1px;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
}