/* Base Styles */
.styled-container {
    padding: 16px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.styled-section {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* overflow: auto; */
    flex: 1;
    margin-bottom: 16px;
    /* max-height: 300px; */
    /* Add these for better scrollbar appearance */
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    /* For Firefox */
}

.styled-section-overflow {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: auto;
    flex: 1;
    margin-bottom: 16px;
    max-height: 300px;
    /* Add these for better scrollbar appearance */
    scrollbar-width: thin;
    /* For Firefox */
    scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    /* For Firefox */
}

/* For Webkit browsers (Chrome, Safari) */
.styled-section::-webkit-scrollbar {
    width: 8px;
}

.styled-section::-webkit-scrollbar-track {
    background: transparent;
}

.styled-section::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.section-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 12px;
    color: #1a1a1a;
}

/* Button Styles */
.styled-button {
    background-color: #8799b6;
    color: white;
    padding: 20px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.styled-button:hover {
    background-color: #495166;
}

.cancel-button-ex {
    background-color: hsl(5, 67%, 42%);
    color: #ffffff;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.cancel-button-ex:hover {
    background-color: #a22009;
}

.save-button-ex {
    background: #4CAF50;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.save-button-ex:hover {
    background-color: #388e3c;
}

.submit-rec-button {
    background-color: #8884d8;
    color: #ffffff;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

/* Input Styles */
.styled-input {
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 14px;
}

/* Slider Styles */
.styled-slider {
    display: flex;
    align-items: left;
    margin-bottom: 16px;
    justify-content: left;
    padding-left: 20px;
}

.slider-label {
    width: 80px;
    color: #4a5568;
    font-size: 10px;
}

.slider-container {
    flex: none;
    width: 300px;
    padding-top: 20px;
}

.slider-input {
    appearance: none;
    background: transparent;
    border: 1px solid #cbd5e0;
    border-radius: 8px;
    height: 8px;
    margin: 0 8px;
}

.slider-value {
    margin-left: 8px;
    color: #4a5568;
}

/* Activity Styles */
.activity-bubble {
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 2px;
    padding: 6px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid #ddd;
    gap: 8px;
    cursor: move;
}

.activity-bubble.selected {
    background-color: #e3f2fd;
}

.activity-bubble.styled {
    background-color: #cce5ff;
    border: 1px solid #66b0ff;
    color: #004085;
}

.activity-name {
    font-weight: 500;
    color: #1a1a1a;
    margin-right: 4px;
    font-size: 11px;
}

.activity-controls {
    display: flex;
    gap: 8px;
    align-items: center;
}

.remove-button {
    position: absolute;
    top: -4px;
    right: -4px;
    display: none;
    height: 16px;
    width: 16px;
    align-items: center;
    justify-content: center;
    background-color: #ef4444;
    color: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
}

.activity-bubble:hover .remove-button {
    display: flex;
}

.remove-button:hover {
    color: #f44336;
}

/* Activity Group Styles */
.activity-group {
    height: fit-content;
    max-height: 100%;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 0;
    overflow-y: auto;
}

.activity-group-title {
    font-weight: 600;
    margin-bottom: 8px;
    color: #1a1a1a;
}

.activity-bubble-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    padding: 4px;
}

/* Activity Group Color Coding */
.activity-group-avoid {
    background-color: #fee2e2;
    border: 1px solid #fecaca;
}

.activity-group-increase {
    background-color: #dcfce7;
    border: 1px solid #bbf7d0;
}

.activity-group-decrease {
    background-color: #ffedd5;
    border: 1px solid #fed7aa;
}

.activity-group-prefer {
    background-color: #dbeafe;
    border: 1px solid #bfdbfe;
}

.activity-bubble.activity-group-avoid {
    background-color: #fecaca;
    border: 1px solid #ef4444;
    color: #991b1b;
}

.activity-bubble.activity-group-increase {
    background-color: #bbf7d0;
    border: 1px solid #22c55e;
    color: #166534;
}

.activity-bubble.activity-group-decrease {
    background-color: #fed7aa;
    border: 1px solid #f97316;
    color: #9a3412;
}

.activity-bubble.activity-group-prefer {
    background-color: #bfdbfe;
    border: 1px solid #3b82f6;
    color: #1e40af;
}

/* Chart Styles */
.chart-container {
    width: 100%;
    height: 100%;
}

.chart-wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.health-domain-chart,
.weekly-allocation-chart {
    height: 100%;
    width: 100%;
}

.chart-section {
    height: 400px;
}

/* Grid Layouts */
.top-grid {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr;
    gap: 20px;
    margin-bottom: 50px;
    height: 300px;
}

.grid-2-cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    margin-bottom: 24px;
}

.grid-3-cols {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 30px;
}

.time-allocation-grid {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    gap: 20px;
    margin-bottom: 24px;
}

/* Layout Components */
.left-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

.middle-column,
.right-column {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.half-height {
    height: 100px;
    min-height: 0;
}

/* Rationale Boxes */
.rationale-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f8fafc;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    width: 100%;
}

.rationale-text {
    font-size: 0.8rem;
    line-height: 1.5;
    white-space: pre-line;
    color: #4b5563;
    margin: 0;
}

.full-width-rationale {
    margin-bottom: 10px;
    width: 100%;
    clear: both;
}

/* Metric Boxes */
.metric-box {
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    text-align: center;
}

.metric-value {
    font-size: 1.8em;
    font-weight: bold;
    color: #8884d8;
}

.metric-label {
    margin-top: 5px;
    color: #666;
    font-size: 0.9em;
}

/* Goals Box */
.goals-box {
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
}

.goal-item {
    margin-bottom: 6px;
    font-size: 0.9em;
}

.goal-item:last-child {
    margin-bottom: 0;
}

/* Loading State */
.loading-state {
    text-align: center;
    padding: 40px;
    color: #6b7280;
}

/* Chart Specific Containers */
.health-domain-chart-wrapper,
.hsd-chart-wrapper {
    padding: 5px;
    height: 250px;
}

.weekly-chart-section,
.weekly-rationale-section {
    height: 200px;
    overflow: hidden;
}

/* Ensure charts take appropriate height */
.recharts-responsive-container {
    height: 250px !important;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .activity-layout {
        flex-direction: row;
        gap: 24px;
    }

    .chart-layout {
        flex-direction: row;
        gap: 24px;
    }
}

/* Activity groups container */
.activity-groups-horizontal {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    gap: 4px;
    margin: 0 0;
    width: 100%;
}

/* Weekly allocation layout */
.weekly-allocation-container {
    display: flex;
    gap: 20px;
    margin-bottom: 16px;
    height: 200px;
}

.weekly-chart-section,
.weekly-rationale-section {
    flex: 1;
    height: 100%;
    display: flex;
}

.activity-detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Exercise Schedule Styles */
.styled-activity {
    padding: 16px;
    background-color: #f8fafc;
    border-radius: 8px;
    margin-bottom: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.activity-detail {
    font-size: 0.9rem;
    color: #4b5563;
    padding: 4px 0;
}

.activity-detail:last-child {
    margin-bottom: 0;
}

/* Modal Styles */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    z-index: 1500;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1400;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.form-group input:disabled,
.form-group select:disabled,
.form-group .disabled {
    background-color: #f5f5f5;
    color: #999;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Add a helper text to show why inputs are disabled */
.form-group input:disabled::after,
.form-group select:disabled::after,
.form-group .disabled::after {
    content: "Select only one intensity measure";
    display: block;
    font-size: 12px;
    color: #666;
    margin-top: 4px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

/* Activities List */
.activities-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    min-height: 100px;
}

.activity-bubble.dragging {
    opacity: 0.5;
    background: #f0f0f0;
}

.activity-bubble.draggable {
    padding: 10px 15px;
    background-color: #f0f0f0;
    border-radius: 20px;
    cursor: grab;
}

/* Calendar Grid */
.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
    margin: 20px 0;
}

.calendar-day {
    background-color: #fafafa;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    min-height: 300px;
    position: relative;
    overflow-y: auto;
}

.calendar-day-title {
    margin-top: 0;
    text-align: center;
}

.scheduled-activity {
    margin: 5px 0;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    z-index: 10;
}

.scheduled-activity:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.scheduled-activity .activity-name {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 4px;
}

.activity-details {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.7);
}

.activity-duration {
    font-weight: 500;
}

.activity-intensity {
    font-style: italic;
}

/* Responsive */
@media (max-width: 768px) {
    .calendar-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Add this CSS to ensure the clickable area is appropriately styled */
.activity-content {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Ensure the parent ExerciseRecommendation modal has a lower z-index */
.exercise-recommendation-modal {
    z-index: 1000;
}

.exercise-recommendation-overlay {
    z-index: 900;
}

/* Add/update these CSS rules */

.scheduled-activity {
    /* Add hardware acceleration */
    transform: translateZ(0);
    will-change: transform;
    /* Rest of existing styles */
}

.activity-bubble.draggable {
    /* Add hardware acceleration */
    transform: translateZ(0);
    will-change: transform;
    /* Update colors to match new palette */
    background-color: #F5F5F5;
    /* Rest of existing styles */
}

.calendar-day {
    /* Optimize scrolling */
    -webkit-overflow-scrolling: touch;
    /* Rest of existing styles */
}

/* Add smooth transitions */
.scheduled-activity,
.activity-bubble {
    transition: transform 0.2s cubic-bezier(0.2, 0, 0, 1);
}

/* Optimize drag preview */
.activity-bubble.dragging {
    opacity: 0.8;
    transform: scale(1.05);
    transition: transform 0.2s cubic-bezier(0.2, 0, 0, 1);
}

.remove-activity-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 2px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.remove-activity-button:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #333;
}

.calendar-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}


.hsd-plot-container {
    width: 100%;
    height: 300px;
    margin-bottom: 20px;
}


.calendar-carousel {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.carousel-content-wrapper {
    flex: 1;
    overflow: hidden;
    position: relative;
}

.carousel-content {
    width: 100%;
    transition: transform 0.3s ease-in-out;
}

.carousel-content.slide-left {
    animation: slideLeft 0.3s ease-in-out;
}

.carousel-content.slide-right {
    animation: slideRight 0.3s ease-in-out;
}

@keyframes slideLeft {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideRight {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

.carousel-button {
    z-index: 1;
    padding: 10px 15px;
    background: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 10px;
}

.carousel-button:hover {
    background: #e0e0e0;
}

.calendar-day-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    background: white;
    border-radius: 8px;
    overflow: visible;
    min-height: 200px;
    padding-top: 8px;
}


.intensity-bar {
    position: absolute;
    bottom: calc(100% - 8px);
    left: 0;
    right: 0;
    height: 25px;
    border-radius: 8px 8px 0 0;
    transform-origin: bottom;
    transition: transform 0.3s ease, background-color 0.3s ease;
    z-index: 1;
}

.calendar-day {
    position: relative;
    flex: 1;
    padding: 12px;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow-y: auto;
    z-index: 0;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0px;
    padding: 0px;
    background: #ffffff;
    border-radius: 8px;
}

.scheduled-activity {
    position: relative;
    z-index: 2;
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 4px;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.activities-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 10px;
}

.activities-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.activities-chart-column {
    background: #ffffff;
    border-radius: 8px;
}

.activities-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.activities-group h4 {
    margin: 0;
    padding: 5px 10px;
    font-size: 0.9em;
    color: #666;
}

.activities-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    min-height: 50px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
}

.activities-group.resistance .activities-row {
    background: rgba(255, 216, 128, 0.1);
    /* Light orange background */
}

.activities-group.aerobic .activities-row {
    background: rgba(173, 216, 230, 0.1);
    /* Light blue background */
}

.activities-group.activity .activities-row {
    background: rgba(144, 238, 144, 0.1);
    /* Light green background */
}

/* Update grid proportions and sizes */
.activities-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    /* Change to 2/3 1/3 split */
    gap: 20px;
    padding: 10px;
    max-height: 300px;
    /* Reduce overall height */
}

.activities-column {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* Reduce gap */
    overflow-y: auto;
    /* Allow scrolling if needed */
}


.activities-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* Reduce gap */
}

.activities-group h4 {
    margin: 0;
    padding: 3px 8px;
    /* Reduce padding */
    font-size: 0.85em;
    color: #666;
}

.activities-row {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    /* Reduce gap */
    padding: 5px;
    /* Reduce padding */
    min-height: 40px;
    /* Reduce minimum height */
}

/* Make activity bubbles smaller */
.activity-bubble.draggable {
    padding: 5px 10px;
    font-size: 0.85em;
}

/* Update calendar related styles */
.calendar-grid {
    gap: 5px;
    /* Reduce gap between days */
    padding: 5px;
}

.calendar-day {
    padding: 8px;
    min-height: 250px;
    /* Reduce minimum height */
}

.calendar-day-title {
    font-size: 0.9em;
    margin-bottom: 5px;
}

.scheduled-activity {
    margin-bottom: 5px;
    padding: 5px;
    min-height: 40px;
    /* Reduce minimum height */
}

.total-time-display {
    background-color: #f5f5f5;
    padding: 4px 8px;
    /* Reduce padding */
    border-radius: 4px;
    margin-bottom: 8px;
    /* Reduce margin */
    font-weight: bold;
    text-align: right;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    width: fit-content;
    /* Make the box not full width */
}