.detailAchievement {
    margin-top: 30px;
    padding: 12px 24px;
    border: 1px solid #DEE2EC;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
    display: flex; }

.avatar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  &>div {
    margin: 0 auto; }
  img {
    width: 100%;
    object-fit: cover; }
  .avatarContainer {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
    background: #BAC3CE; }
  .expertInfo {
    margin-left: 15px;
    color: #8691A7;
     :global(.ant-select-selection) {
      border: none;
      margin-left: -11px; }
     :global(.ant-select-selection) {
      box-shadow: none;
      display: inline-block; }
     :global(.ant-select-selection__rendered) {
      font-size: 18px;
      color: #52606C; }
     :global(.ant-select-arrow) {
      color: #52606C; } }
  .fullName {
    font-size: 18px;
    color: #52606C;
    text-transform: capitalize;
    margin-bottom: 5px; } }

.summary {
  font-size: 14px;
  color: #383E48;
  font-family: Gilroy; }

.overview {
  background: rgba(239,242,245,.4);
  border-color: rgba(221,221,221,.4);
  .pipeChart:first-child {
    padding-right: 160px; } }

.pipeChart {
    display: flex;
    padding: 20px 0;
    align-items: center;
    &:last-child {
      padding-right: 74px; }
     :global(.ant-divider-vertical) {
      height: 84px;
      margin: auto 33px; }
    .description {
      margin-left: 24px;
      .progress {
        color: #0D2350;
        font-size: 24px;
        line-height: 38px; }
      .text {
        color: #8691A7;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.3px; } }
     :global(.ant-progress-circle-path) {
      // stroke: #0D2350 !important
      stroke: #FF6B00 !important; } }
.nutrition, .rejuvenation {
  justify-content: space-between;
  .avatar {
    margin-right: 100px; }

   :global(.ant-divider-vertical) {
    height: 84px;
    margin: auto 33px; }
   :global(.ant-progress-circle-path) {
    stroke: #7FB800 !important; } }

.rejuvenation {
   :global(.ant-progress-circle-path) {
    stroke: #5C00D1 !important; } }



.emptyStatus {
  text-align: center;
  margin-top: 150px;
  div {
    font-family: Heebo;
    margin: 25px;
    font-size: 14px;
    color: #79879B;
    opacity: 0.8;
    letter-spacing: 0.3px; }
  button {
    width: 156px;
    height: 40px; } }
.exerciseContainer {
  margin-top: 27px;
  .title {
    margin-bottom: 15px;
    font-size: 12px;
    color: #52606C; }
  .exerciseList {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    &.todo {
      flex-wrap: wrap; }
    .exerciseItem {
      min-width: 145px;
      width: 14.28%;//150px
      position: relative;
      transition: background-color 0.2s ease;

      &.dragOver {
        background-color: rgba(123, 190, 255, 0.1);
        border-radius: 4px; }

      .dragging {
        border: 1px dashed #7fbcff;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); } } } }

.updating {
  pointer-events: none; }

.updatingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 8px;

  span {
    background-color: #003366;
    color: white;
    padding: 4px 10px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); } }
